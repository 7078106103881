import React from "react"
import styled from "styled-components"
import { Container } from "@material-ui/core"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ActivateForm from "../../components/active-form"
import PageTitle from "../../components/page-title"
import permIdentity from "../../images/perm-identity.svg"
import { breakpoints } from "../../components/variables"

const RequestDemoW = styled.div``
const RequestDemoIW = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column-reverse;
  }
`

const Activate = ({ location }) => {
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get("email")
  const code = searchParams.get("code")

  if (typeof window !== `undefined`) {
    localStorage.setItem("isNewCustomer", true)
  }

  return (
    <Layout>
      <SEO title="Activate User Account" />
      <PageTitle title="Activate User Account" icon={permIdentity} />
      <RequestDemoW>
        <Container>
          <RequestDemoIW>
            <ActivateForm email={email} code={code} />
          </RequestDemoIW>
        </Container>
      </RequestDemoW>
    </Layout>
  )
}

export default Activate
